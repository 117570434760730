<template>
  <CheckoutLayout :step="3">
    <div class="row">
      <div class="col-12 col-xxl-8">
        <div class="checkout__bank-transfer">
          <h2 class="text text--xs-mt-0 text--xs-header-3 text--lg-header-2">
            Dane do przelewu bankowego
          </h2>

          <p>
            Dziękujemy za złożenie zamówienia.<br />
            Aby sfinalizować zamówienie wykonaj przelew bankowy używając
            poniższych danych:
          </p>

          <div class="checkout__details-list">
            <ul class="details-list">
              <li class="details-list__item">
                <span class="details-list__label">Odbiorca:</span>
                BLUE LIGHT SP. Z O.O.
              </li>
              <li class="details-list__item">
                <span class="details-list__label">Adres:</span>
                Ul. Kraszewskiego 128, 33-380 Krynica-Zdrój
              </li>
              <li class="details-list__item">
                <span class="details-list__label">Nr konta:</span>
                02 1050 1722 1000 0090 8025 0708
              </li>
              <li class="details-list__item">
                <span class="details-list__label">Kwota przelewu:</span>
                {{ cartTotal }}
              </li>
              <li class="details-list__item">
                <span class="details-list__label">Tytuł przelewu:</span>
                {{ orderId }}
              </li>
            </ul>
          </div>

          <p class="checkout__text">
            W razie problemów skontaktuj się z nami:
            <a :href="mailto" class="checkout__email">
              biuro@fotoregiony.pl
            </a>
          </p>
        </div>
      </div>
    </div>
  </CheckoutLayout>
</template>

<script>
import CheckoutLayout from '../checkout-layout/checkout-layout.vue';

export default {
  name: 'PaymentBankTransfer',
  components: {
    CheckoutLayout,
  },
  props: {
    orderId: {
      required: true,
      type: String,
    },
  },
  metaInfo: {
    title: 'Szczegóły konta bankowego',
  },
  data: () => ({
    cartTotal: '',
  }),
  computed: {
    mailto() {
      return `mailto:biuro@fotoregiony.pl?subject=Problem z zamówieniem nr: ${this.orderId}`;
    },
  },
};
</script>
